export default function MapSection() {
    return (
        <section className="section section-white">
            <div className="container_map">
                <div className="map">
                    <div className="map_text item--text">
                        <div className="map_padding">
                            <h1>Наша адреса</h1>
                            <p>М. Рівне, Здолбунівський район, вул Шевченка 6</p>
                            <div className="map_button">
                                <a className="text-center" href="https://maps.app.goo.gl/R2cWbgpg1MRGi6c16">Відкрити
                                    Google карти</a>
                            </div>
                        </div>
                    </div>
                    <div className="div_map">
                        <div className="d-block map_height w-100">
                            <iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0"
                                    marginWidth="0"
                                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=50.55226999158362,%2026.256379853541198+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}