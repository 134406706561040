import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Carousel, Button, Modal} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';

function CasesSection() {

    const carouselRef = useRef();
    const [slidesToShow, setSlidesToShow] = useState(3); // Значение по умолчанию для больших экранов
    const [visibleModal, setVisibleModal] = useState(null); // Для управления видимостью модальных окон

    const updateSize = () => {
        setSlidesToShow(window.innerWidth < 768 ? 1 : 3);
    };

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize(); // Инициализируем при монтировании
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const slides = [
        {
            id: "2",
            youtubeVideoId: "JjuHLy5R7yU",
            imgSrc: "images/cases/car2.jpg",
            model: "Ford Ecosport Titanium",
            engine: "2.0L бензин",
            year: "2020",
            price: "$12000"
        },
        {
            id: "8",
            youtubeVideoId: null,
            imgSrc: "images/cases/car8.jpg",
            model: "Hyundai Tucson",
            engine: "2.5L бензин",
            year: "2022",
            price: "$25000"
        },

        {
            id: "6",
            youtubeVideoId: "EdnH0Nt7fFQ",
            imgSrc: "images/cases/car6.jpg",
            model: "Jeep Compass Latitude",
            engine: "2.4L бензин",
            year: "2019",
            price: "$14000"
        },

        {
            id: "3",
            youtubeVideoId: null,
            imgSrc: "images/cases/car3.jpg",
            model: "Jeep Compass Latitude",
            engine: "2.4L бензин",
            year: "2020",
            price: "$13000"
        },
        {
            id: "5",
            youtubeVideoId: "tty8tLZ87No",
            imgSrc: "images/cases/car5.jpg",
            model: "Ford Escape SEL",
            engine: "1.5L бензин",
            year: "2018",
            price: "$11500"
        },

        {
            id: "4",
            youtubeVideoId: null,
            imgSrc: "images/cases/car4.jpg",
            model: "Hyundai Tucson",
            engine: "2.0L бензин",
            year: "2018",
            price: "$14500"
        },

        {
            id: "1",
            youtubeVideoId: null,
            imgSrc: "images/cases/car1.jpg",
            model: "Ford Fusion Energi Titanium",
            engine: "2.0L гібрид",
            year: "2016",
            price: "$14000"
        },

        {
            id: "7",
            youtubeVideoId: null,
            imgSrc: "images/cases/car7.jpg",
            model: "Chevrolet Camaro",
            engine: "6.2L бензин",
            year: "2020",
            price: "$35000"
        },

        {
            id: "9",
            youtubeVideoId: null,
            imgSrc: "images/cases/car9.jpg",
            model: "Mercedes-Benz GLC 43 AMG",
            engine: "3.0L бензин",
            year: "2020",
            price: "$47000"
        },
    ];

    const next = () => {
        carouselRef.current.next();
    }

    const previous = () => {
        carouselRef.current.prev();
    }

    const showModal = (modalId) => {
        setVisibleModal(modalId);
    }

    const closeModal = () => {
        setVisibleModal(null);
    }

    const videoRef = useRef(null);

    const stopVideoPlayback = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
    }, []);

    const renderCarButton = (imgSrc, modalId, carModel, engine, carYear, price, youtubeVideoId) => (
        <div className="width_car_button text-center">
            <button className="car_button mb-2 " onClick={() => showModal(modalId)}>
                <img src={imgSrc} alt="" className="d-block w-100 cases-car-img"/>
            </button>

            <Modal
                title={carModel}
                visible={visibleModal === modalId}
                onCancel={() => {
                    closeModal();
                    stopVideoPlayback(); // Останавливаем воспроизведение при закрытии модального окна
                }}
                footer={null}
            >
                {(youtubeVideoId) ? (
                    <iframe
                        ref={videoRef}
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${youtubeVideoId}?enablejsapi=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (<img src={imgSrc} alt={carModel} style={{width: '100%'}}/>)}

                <h5>{carModel}, {engine}, <span style={{color: '#16161699'}}>{carYear}</span></h5>
                <p>Ціна під ключ в Україні: <span style={{fontWeight: 600}}>{price}</span></p>

            </Modal>
            <h5>{carModel}, <span style={{color: '#16161699'}}>{carYear}</span></h5>
            <p>Ціна під ключ в Україні: <span style={{fontWeight: 600}}>{price}</span></p>
            {(youtubeVideoId) ? (<i className="fa-brands fa-youtube icon-overlay"></i>) : null}

        </div>
    );

    return (
        <section className="section">
            <div className="container carousel_padding">
                <div className="carousel_logo d-flex">
                    <h3 className="mb-5"><span style={{color: '#E62626'}}>Наші</span> кейси</h3>
                </div>
                <div style={{position: 'relative'}} className='px-5'>
                    <Carousel ref={carouselRef} dots={false} slidesToShow={slidesToShow} arrows>
                        {slides.map((car, index) => (
                            <div key={index}>
                                <div className="row justify-content-center">
                                    {renderCarButton(car.imgSrc, car.id, car.model, car.engine, car.year, car.price, car.youtubeVideoId)}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    <Button style={{position: 'absolute', top: '50%', left: 0, transform: 'translate(0, -50%)'}}
                            type="text" onClick={previous}>
                        <LeftOutlined style={{fontSize: '24px'}}/>
                    </Button>
                    <Button style={{position: 'absolute', top: '50%', right: 0, transform: 'translate(0, -50%)'}}
                            type="text" onClick={next}>
                        <RightOutlined style={{fontSize: '24px'}}/>
                    </Button>
                </div>
            </div>
        </section>
    );
}

export default CasesSection;