import {Link} from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer">
            <nav className="footer_padding">
                <div className="container">
                    <div className="footer2">
                        <div className="header2_img">
                            <Link className="img_logo item--text" to="/">
                                <img height="50px" className="logo_imgg" src="images/logo.png"/>
                            </Link>
                        </div>
                        <div className="footer_element">
                            <div className="d-flex footer_element_item1">
                                <div className="logo_footer d-flex">

                                    <div className="logo_footer_1 ps-5">
                                        <div><a className="contact" href="#">Кабінет дилера</a></div>
                                        <div><a className="contact" href="#">Авто в наявності</a></div>
                                    </div>

                                    <div className="logo_footer_1">
                                        <div><a className="contact" href="#">Калькулятор</a></div>
                                        <div><a className="contact" href="#">Меню</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex ms-auto footer_element_item2">
                                <div className="logo_footer_2">
                                    <div className="info_footer_2 ps-5">
                                        <p>Наш номер телефону</p>
                                        <a className="logo_footer_a" href="+380930008872">+38 093 000 8872</a>
                                    </div>
                                    <div className="footer_padding">
                                        <a className="logo_footer_a" href="#"><i
                                            className="fa-solid fa-paper-plane"/></a>
                                        <a className="logo_footer_a" href="https://www.instagram.com/gromauto.ua/"><i
                                            className="fa-brands fa-instagram"/></a>
                                        <a className="logo_footer_a" href="#"><i
                                            className="fa-brands fa-facebook-f"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </footer>

    );
}