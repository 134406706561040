import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const Header = () => {
  return (
    <header className="d-block" style={{ backgroundColor: '#161616' }}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light navbar-dark">
          <div className="container-fluid">
            <Link className="logo_header_a_info logo_header_a logo_header_buttons" to="/">
              <i className="fa-solid fa-right-from-bracket"> </i>
            </Link>
            <Link to="/">
              <img height="50px" className="logo_img" src="images/logo.png" alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"> </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 top-menu">
                <hr className="hr_none" />
                <li className="nav-item">
                  <Link className="nav-link" to="/calculator">Калькулятор</Link>
                </li>
                <hr className="hr_none" />
              {/*  <li className="nav-item">*/}
              {/*    <Link className="nav-link" to="#">Меню</Link>*/}
              {/*  </li>*/}
              {/*  <hr className="hr_none" />*/}
              </ul>
              <div className="logo_header">
                <div className="logo_header_2">
                  <a className="logo_header_a tel_1" href="tel:+380930008872">+38 093 000 8872</a>
                  <a className="logo_header_a" href="https://www.instagram.com/gromauto.ua/" target="_blank"
                     rel="noopener noreferrer"><i className="fa-brands fa-instagram"> </i></a>
                  <a className="logo_header_a" href="https://www.tiktok.com/@grom_auto.ua" target="_blank"
                     rel="noopener noreferrer"><i className="fa-brands fa-tiktok"> </i></a>
                  <a className="logo_header_a" href="#"><i className="fa-brands fa-viber"> </i></a>
                  <a className="logo_header_a" href="#"><i className="fa-solid fa-paper-plane"> </i></a>
                  <a className="logo_header_a" href="#"><i className="fa-brands fa-facebook-f"> </i></a>
                </div>
              </div>
              <div className="logo_header_3">
                <Link className="logo_header_a_info logo_header_a logo_header_a_3 scroll-lead-form" to="#">Зв’язатись з нами</Link>
                <Link className="logo_header_a_info logo_header_a logo_header_a_2" to="#"><i className="fa-solid fa-right-from-bracket"></i></Link>
                <Link className="logo_header_a tel_2" to="tel:+380930008872">+38 093 000 8872</Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;