export default function OwnServiceSection() {
    return (
        <section className="section section-white">
            <div className="container">
                <div className="section_hangar">
                    <div className="row">
                        <div className="section_item_hangar item--text col-lg-5 col-sm-12">
                            <div className="section_title pb-3">
                                <h1>Власний Автосервіс</h1>
                            </div>
                            <div className="section_text pb-5">
                                <p>Розмитнене авто з-за кордону передається нашим спеціалістам, які ремонтують та
                                    відновлюють вашу машину на нашому автосервісі</p>
                            </div>
                            <div className="hangar_button d-flex">
                                {/* Используйте Link вместо a, если это внутренняя ссылка */}
                                <a href="#" className="scroll-lead-form">Зв’язатись з нами</a>
                                {/* Пример с Link: <Link to="/contact" className="scroll-lead-form">Зв’язатись з нами</Link> */}
                            </div>
                        </div>

                        <div className="section_item col-lg-7 col-sm-12">
                            {/* Убедитесь, что путь к изображению правильный относительно публичной папки вашего проекта */}
                            <img className="img-fluid d-block justify-content-center m-auto"
                                 src="images/auto_service.png" alt="Автосервис"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}