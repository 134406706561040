import React, {useState} from 'react';
import {Form, Input, Select, Button, message, Row, Col, Card, Divider} from 'antd';
import axios from 'axios';

const {Option} = Select;

const BASE_URL = process.env.REACT_APP_BACKEND_URL + '/calculator';

const URLS = {
    auctionFee: BASE_URL + '/auction_fee',
    locations: BASE_URL + '/locations',
    deliveryCost: BASE_URL + '/delivery_cost',
    tax: BASE_URL + '/tax',
    fullCost: BASE_URL + '/full_cost'
}

const AuctionForm = () => {
    const [form] = Form.useForm();
    const [auctionTax, setAuctionTax] = useState('-');
    const [locations, setLocations] = useState([]);
    const [deliveryCosts, setDeliveryCosts] = useState({});
    const [customsCosts, setCustomsCosts] = useState({});
    const [totalCost, setTotalCost] = useState('-');


    const handleAuctionPriceChange = async (value) => {
        if (value) {
            const response = await axios.post(URLS.auctionFee, {cost: value});
            setAuctionTax(response.data['auction tax']);
        } else {
            setAuctionTax('-');
        }
        await handleTax();
    };


    const handleGetLocations = async () => {
        // Получаем локации для выбранного аукциона
        axios.get(URLS.locations + `?auction=${form.getFieldValue('auction')}`)
            .then(response => {
                form.setFieldValue('location', '');
                setLocations(response.data);
            })
            .catch(error => {
                message.error('Ошибка при получении данных аукциона');
            });
    };


    const handleDeliveryCosts = async () => {
        try {
            const values = await form.validateFields(['auction', 'location', 'containerVolume']);
            const data = {place_id: values.location, container_volume: values.containerVolume}
            const response = await axios.post(URLS.deliveryCost, data);
            setDeliveryCosts(response.data);
        } catch (error) {
            // Если ошибка валидации или запроса

        }
    };

    const handleTax = async () => {
        try {
            const values = await form.validateFields(['auctionPrice', 'engineType', 'engineVolume', 'carYear']);
            const data = {
                cost: values.auctionPrice,
                engine_type: values.engineType,
                engine_volume: values.engineVolume,
                age: values.carYear
            }
            const response = await axios.post(URLS.tax, data);
            setCustomsCosts(response.data);
        } catch (error) {
            // Если ошибка валидации или запроса

        }
    };

    const handleFullCost = async () => {
        try {
            const values = await form.getFieldsValue();
            const data = {
                cost: values.auctionPrice,
                place_id: values.location,
                container_volume: values.containerVolume,
                engine_type: values.engineType,
                engine_volume: values.engineVolume,
                age: values.carYear
            }
            const response = await axios.post(URLS.fullCost, data);
            setTotalCost(response.data.total);
        } catch (error) {
            message.error('Ошибка при получении полной стоимости');
        }
    };

    return (
        <Card style={{maxWidth: 800, margin: 'auto'}} className='custom-dark'>
            <Form form={form} layout="vertical" onFinish={handleFullCost}>
                <Divider orientation="center">Купівля</Divider>

                <Row gutter={[16, 16]}>
                    {/* Part 1: Auction Cost */}
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="auctionPrice"
                            label="Вартість на аукціоні"
                            rules={[{required: true, message: 'Будь ласка, введіть вартість!'}]}
                        >
                            <Input type="number" min={0} onChange={(e) => handleAuctionPriceChange(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <div>Вартість зі збором аукціону: <span>{auctionTax}</span>$</div>
                    </Col>

                    {/* Part 2: Delivery Cost */}
                    <Divider orientation="center">Доставка</Divider>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="auction"
                            label="Аукціон"
                            rules={[{required: true, message: 'Пожалуйста, выберите аукцион!'}]}
                        >
                            <Select onChange={handleGetLocations}>
                                <Option value="IAAI">IAAI</Option>
                                <Option value="Copart">Copart</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="location"
                            label="Локація"
                            rules={[{required: true, message: 'Пожалуйста, выберите локацію!'}]}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleDeliveryCosts}
                            >
                                {locations.map(loc => (
                                    <Option key={loc.id} value={loc.id}>{loc.location}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="containerVolume"
                            label="Загрузка контейнера"
                            rules={[{required: true, message: 'Пожалуйста, выберите объем контейнера!'}]}
                        >
                            <Select onChange={handleDeliveryCosts}>
                                <Option value="1/4">1/4</Option>
                                <Option value="1/3">1/3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>Доставка до порту США: <span>{deliveryCosts.place || '—'}</span>$</div>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>Доставка по океану: <span>{deliveryCosts.port || '—'}</span>$</div>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>Разом: <span>{deliveryCosts.total || '—'}</span>$</div>
                    </Col>
                    {/* Part 3: Customs */}
                    <Divider orientation="center">Митниця</Divider>

                    <Col xs={24} md={8}>
                        <Form.Item
                            name="engineType"
                            label="Тип двигуна"
                            rules={[{required: true, message: 'Пожалуйста, выберите тип двигателя!'}]}
                        >
                            <Select onChange={handleTax}>
                                <Option value="petrol">Бензиновий</Option>
                                <Option value="diesel">Дизельний</Option>
                                <Option value="electric">Електричний</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="engineVolume"
                            label="Обʼєм двигуна"
                            rules={[{required: true, message: 'Пожалуйста, введите объем двигателя!'}]}
                        >
                            <Input type="number" min={0} onChange={handleTax}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="carYear"
                            label="Рік машини"
                            rules={[{required: true, message: 'Пожалуйста, выберите год машины!'}]}
                        >
                            <Select onChange={handleTax}>
                                {Array.from(new Array(15), (val, index) => ({
                                    year: new Date().getFullYear() - index,
                                    age: index
                                })).map(({year, age}, index) => (
                                    <Option key={year} value={age}>{year}</Option>
                                ))}
                                <Option key="older" value="15">{
                                    `${new Date().getFullYear() - 15} і старше`
                                }</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <div>Ввізне мито: <span>{customsCosts.duty || '—'}</span>$</div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div>Акциз: <span>{customsCosts.excise || '—'}</span>$</div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div>ПДВ: <span>{customsCosts.vat || '—'}</span>$</div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div>Разом: <span>{customsCosts.total || '—'}</span>$</div>
                    </Col>

                    {/* Full Cost Button */}
                    <Col span={24}>
                        <Button type="primary" htmlType="submit">Отримати повну вартість</Button>
                    </Col>
                </Row>
            </Form>
            {/* total costs: */}
            <div style={{marginTop: '20px'}}>
                <h3>Загальна вартість: {totalCost}$</h3>
                <h6>Ціна під ключ: {Number(totalCost) + 1300||"-"}$ <span className="fs-6">( +1300$ Комплекс послуг по доставці в україну)</span></h6>
            </div>


        </Card>
    );
};

export default AuctionForm;