import Landing from "./components/Landing/Landing";
import Header from "./components/Header/Header";
import {Route, Routes} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Calculator from "./components/Calculator/Calculator";

function App() {
    return (
        <div className="app-container">
            <Header/>
            <div className="content-wrap">
                <Routes>
                    <Route path='/' element={<Landing/>}/>
                    <Route path='/calculator' element={<Calculator/>}/>
                    <Route path='*' element={<h1>404</h1>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
