import {Form, Input, Button, message} from "antd";
import axios from "axios";

const URL = process.env.REACT_APP_BACKEND_URL + '/leads/'

export default function LeadFormSection() {

    const [form] = Form.useForm()

    const onFinish = async (values) => {
        await axios.post(URL, {phone_number: values.phone, name: values.name})
        message.success('Дякуємо за звернення! Ми зв’яжемося з вами найближчим часом.')
        form.resetFields()
    }

    return (
        <section className="section section-red" id="lead-form-section">
            <div className="container">
                <div className="contact_2">
                    <div className="contact_text">
                        <h1>Зв’яжіться з нами, щоб отримати персональний підбір машини</h1>
                    </div>
                    <Form form={form} name="lead-form" onFinish={onFinish}>
                        <div className="row">
                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <p>Ім’я</p>
                                <div className="col-lg-6 w-100 h-100">
                                    <div className="form-group">
                                        <Form.Item name="name">
                                            <Input type="text" placeholder="Ім’я" className="form-control"
                                                   id="lead-form-name"
                                                   required/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <p>Номер телефону</p>
                                <div className="col-lg-6 w-100 h-100">
                                    <div className="form-group">
                                        <Form.Item name="phone" >

                                            <Input type="text" placeholder="+380 99 999 9999" className="form-control"
                                                   required/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <div>
                                    <p style={{color: '#E62626'}}>.</p>
                                    <Button className="btn btn-outline-success w-100 h-100 form-control_2"
                                            htmlType="submit">Отримати підбір авто
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
}