import AuctionForm from "./form";
import {ConfigProvider, theme} from "antd";


export default function AuctionCostCalculator() {
    return (
        <ConfigProvider

            theme={{
                algorithm: theme.darkAlgorithm,

                token: {
                  borderRadius: '3px'
                },

                components: {
                    Button: {
                        colorPrimary: '#E62626',
                        colorPrimaryHover: '#C21F1F',
                    }
                }
            }}
        >
            <main className="calculator-page">
                <div className="container">
                    <h1 className="text-center calculator_h1">Розрахунок вартості авто</h1>
                    <AuctionForm/>
                </div>
            </main>
        </ConfigProvider>
    );
}

