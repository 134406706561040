function BenefitsSection() {
    const items = [
        {
            icon: "fa-solid fa-book-open",
            title: "Звітність",
            text: "Після індивідуального підбору авто, ми гарантуємо ретельну перевірку кожного лоту через надійні ресурси в США",
        },
        {
            icon: "fa-solid fa-wrench",
            title: "Професійний контроль якості",
            text: "Наші спеціалісти ретельно розраховують вартість ремонту та відновлення авто на власному автосервісі перед його придбанням.",
        },
        {
            icon: "fa-solid fa-square-check",
            title: "Гарантована доставка та якість",
            text: "Ми забезпечуємо безпечне та надійне транспортування автомобіля на всіх етапах",
        },
        {
            icon: "fa-solid fa-rotate-right",
            title: "Повне обслуговування",
            text: "Від транспортуванням морем до розмитнення та доставки в Україну. Ми гарантуємо комплексний процес, який включає ремонт, відновлення, можливість сертифікації та постановка авто на облік за вашими побажаннями",
        },
    ];

    return (
    <section className="section section-dark">
      <div className="container section_padding">
        <div className="section_header">
          <div className="section_item item--text">
            <div className="section_title">
              <h1><span style={{ color: '#E62626' }}>Індивідуальний</span> Підхід та Повне Обслуговування</h1>
            </div>
          </div>
        </div>

        <div className="row section_padding_2">
          <div className="info_text_2 info_text col-lg-3 col-md-12 col-sm-12">
            <i className="fas fa-book-open"/>
            <h3>Звітність</h3>
            <div className="a">
              <p>Після індивідуального підбору авто, ми гарантуємо ретельну перевірку кожного лоту через надійні ресурси в США</p>
            </div>
          </div>
          <div className="info_text_2 info_text info_padding_2 col-lg-3 col-md-12 col-sm-12">
            <i className="fas fa-wrench"/>
            <h3>Професійний контроль якості</h3>
            <div className="a">
              <p>Наші спеціалісти ретельно розраховують вартість ремонту та відновлення авто на власному автосервісі перед його придбанням.</p>
            </div>
          </div>
          <div className="info_text_2 info_text info_padding_2 col-lg-3 col-md-12 col-sm-12">
            <i className="fas fa-square-check"/>
            <h3>Гарантована доставка та якість</h3>
            <div className="a">
              <p>Ми забезпечуємо безпечне та надійне транспортування автомобіля на всіх етапах.</p>
            </div>
          </div>
          <div className="info_text_2 info_text info_padding_2 col-lg-3 col-md-12 col-sm-12">
            <i className="fas fa-rotate-right"/>
            <h3>Повне обслуговування</h3>
            <div className="a">
              <p>Від транспортуванням морем до розмитнення та доставки в Україну. Ми гарантуємо комплексний процес, який включає ремонт, відновлення, можливість сертифікації та постановка авто на облік за вашими побажаннями.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BenefitsSection