import WelcomeSection from "./WelcomeSection/WelcomeSection";
import AdvantagesSection from "./AdvantagesSection/AdvantagesSection";
import ProposalSection from "./ProposalSection/ProposalSection";
import RoadmapSection from "./RoadmapSection/RoadmapSection";
import CasesSection from "./CasesSection/CasesSection";
import BenefitsSection from "./BenefitsSection/BenefitsSection";
import OwnServiceSection from "./OwnServiceSection/OwnServiceSection";
import FAQSection from "./FAQSection/FAQSection";
import LeadFormSection from "./LeadFormSection/LeadFormSection";
import MapSection from "./MapSection/MapSection";
import {useEffect} from "react";

export default function Landing() {

    useEffect(() => {
        document.querySelectorAll('.scroll-lead-form').forEach((elem) => {
            elem.onclick = () => {
                const div = document.getElementById('lead-form-section');
                const input = document.getElementById('lead-form-name');

                if (div) {
                    div.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
                if (input) {
                    input.focus();
                }
                return false;
            }
        })
    })

    return (
        <>
            <WelcomeSection/>
            <AdvantagesSection/>
            <ProposalSection/>
            <RoadmapSection/>
            <CasesSection/>
            <BenefitsSection/>
            <OwnServiceSection/>
            <FAQSection/>
            <LeadFormSection/>
            <MapSection/>
        </>
    );
}
