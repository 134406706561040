export default function WelcomeSection() {
    return (
        <section className="section section-dark">
            <div className="container">
                <div className="cover">
                    <h1>
                        <span style={{color: '#E62626'}}>Якісно. </span>
                        <span style={{color: '#FFFFFF'}}>Надійно. </span>
                        <span style={{color: '#FFFFFF94'}}>Вигідно. </span>
                    </h1>
                </div>
                <div className="cover_2 d-flex justify-content-between align-items-center">
                    <div>
                        <h3>Купимо та доставимо авто <br/> з США <span style={{color: '#E62626'}}>під ключ</span></h3>
                    </div>
                    <div className="cover_button cover_button_1">
                        {/* Замените на <Link to="#" className="scroll-lead-form">Замовити машину з Америки</Link>, если используете Link */}
                        <a href="#" className="scroll-lead-form">Замовити машину з Америки</a>
                    </div>
                </div>
                <div className="mt-4">
                    <img className="img-fluid mx-auto d-block"
                         src="images/Снимок%20экрана%202024-01-14%20203200.png" alt=""/>
                </div>
                <div className="cover_button cover_button_2">
                    <a href="#" className="scroll-lead-form">Замовити авто з США</a>
                </div>
            </div>
        </section>
    );
}