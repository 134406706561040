export default function FAQSection() {
    return (
        <section className="section section-dark">
            <div className="container">
                <div className="accordion_body m-auto">
                    <div className="accordion_logo pb-5">
                        <h1>Часті Запитання</h1>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Якщо авто вийде дорожче?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Ми виключаємо такі ситуації, так як закладаємо з самого початку всі додаткові
                                    платежі які можуть бути по конкретному лоту.

                                    Ми завжди робимо всі розрахунки максимально точно, та на момент отримання авто його
                                    кінцева ціна часто виходить навіть нижче очікуваної.
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">
                                    Як я буду знати де моє авто?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Ми завжди надаємо вам інформацію де та на якому етапі ваш автомобіль. Також ви
                                    будете мати номер
                                    контейнера, завдяки якому зможете самостійно відслідковувати статус в реальному
                                    часі.
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree">
                                    Які гарантії що я отримаю своє авто?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    З кожним клієнтом ми підписуємо договір, який має юридичну силу. Ви можете
                                    розірвати його, але тільки до моменту того як авто куплене на аукціоні. В даному
                                    договорі ми гарантуємо, що Ваше авто буде привезене в Україну та передане Вам.
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour">
                                    Чи потрібно мені відразу сплатити всю суму прорахунку за авто?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Ні, Ви сплачуєте всі необхідні витрати по авто поступово, в терміни які вимагає
                                    процес. Спочатку іде оплата самого авто на аукціоні. Коли авто консолідоване в
                                    контейнер - ви можете сплатити логістику, але не пізніше 14 днів до дати судозаходу
                                    в порт. Наступний платіж - це оплата митних платежів на реквізити митниці, та оплата
                                    комплексу по доставці авто в Україну.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}