export default function RoadmapSection() {
  return (
    <section className="section section-dark">
      <div className="container">
        <div className="track">
          <h1 className="pb-5">Як проходить весь процес?</h1>
          <img
            className="img-fluid mx-auto img_1"
            src="images/Снимок%20экрана%202024-01-14%20202908.png"
            alt="Процес 1"
          />
          <img
            className="img-fluid mx-auto img_2"
            src="images/photo_2024-01-23_00-18-11.jpg"
            alt="Процес 2"
          />
        </div>
      </div>
    </section>
  );
}