export default function ProposalSection() {
    return (
        <section className="section section-red pt-5 pb-5">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="row">
                    <div className="element_1 col-lg-8 col-sm-12">
                        <h3>Зв’яжіться з нами, щоб отримати персональний підбір машини</h3>
                    </div>
                    <div className="element_2 col-lg-4 col-sm-12 item--text d-flex">
                        <a href="#" className="scroll-lead-form">Отримати підбір авто</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

